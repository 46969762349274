<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h1>Legendary Easter Egg</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <p>
          This egg is a little different than normal easter eggs you may have
          found elsewhere on the site.

          <br />
          <br />

          Legendary Easter Eggs contain
          <strong> SECERT ADVANTAGES</strong>. You can claim these advantages if
          you can complete their respected task, but they come at a
          <strong> RISK</strong>. Good Luck!
        </p>

        <p>Rules:</p>
        <ol>
          <li>You can complete the tasks in any order you choose.</li>
          <li>
            You must show us the task you are trying to complete, before
            attempting it, for it to count.
          </li>
        </ol>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>Tasks</h3>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-btn class="red accent-3" :to="{ name: 'LegendaryTask1' }"
          >Call Your Shot</v-btn
        >
      </v-col>

      <v-col cols="6" v-if="false">
        <v-btn class="yellow accent-3" :to="{ name: 'LegendaryTask1' }"
          >Defeat a Champ</v-btn
        >
      </v-col>

      <v-col cols="6" v-if="false">
        <v-btn class="blue accent-3" :to="{ name: 'LegendaryTask1' }"
          >Instant Win</v-btn
        >
      </v-col>

      <v-col cols="6" v-if="false">
        <v-btn class="purple accent-3" :to="{ name: 'LegendaryTask1' }"
          >Kobe</v-btn
        >
      </v-col>

       <v-col cols="6" v-if="false">
        <v-btn class="green accent-3" :to="{ name: 'LegendaryTask1' }"
          >Frogger</v-btn
        >
      </v-col>

       <v-col cols="6" v-if="false">
        <v-btn class="orange accent-3" :to="{ name: 'LegendaryTask1' }"
          >Immaculate!</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      image: '@images/5.PNG',
    }
  },
}
</script>

<style></style>
